import { Coupon } from "@/model/api/Coupon";
import { POSPortfolioAddProviderServiceDTO } from "@/model/ui/POSPortfolioAddProviderServiceDTO";
import { POSAddProviderServiceStepEnum } from "@/modules/pos/router/POSRoutesEnum";
import { couponService } from "@services/coupon.service";
import { posPortfolioService } from "@services/posPortfolio.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
  },
  emits: ['goStep']
})

export default class ChooseCouponStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: POSPortfolioAddProviderServiceDTO;

  @Prop()
  coupon_code: string;

  coupons: Coupon[] = [];
  
  created() {
    this.dataModel.coupon = new Coupon();
    this.$waitFor(async () => {
      this.coupons = await posPortfolioService.getAvailableCoupons(this.dataModel.service.id, this.dataModel.provider_service.entity_id);
    });
  }

  goSummary() {
    this.$emit('goStep', POSAddProviderServiceStepEnum.PROVIDER_SUMMARY);
  }

  goProviderCalendar() {
    this.$emit('goStep', POSAddProviderServiceStepEnum.PROVIDER_CALENDAR);
  }

  async searchCoupon() {
    try {
      const coupon = await couponService.searchCoupon(this.dataModel.coupon.code);

      if (coupon != null) {
        //TODO: Controlli aggiuntivi
        this.dataModel.coupon = coupon;
      }
    } catch (error) {
      console.error(error);
      this.$errorMessage("", this.$t('pos.portfolio.add_provider_service.choose_coupon_step.error.coupon_not_valid'));
    }
  }
}
