import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_POSPortfolioForm = _resolveComponent("POSPortfolioForm")!

  return (_ctx.model)
    ? (_openBlock(), _createBlock(_component_POSPortfolioForm, {
        key: 0,
        model: _ctx.model,
        onModelFormChanged: _ctx.onModelFormChanged,
        path: _ctx.path,
        onGoBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.back()))
      }, null, 8, ["model", "onModelFormChanged", "path"]))
    : _createCommentVNode("", true)
}