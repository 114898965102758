import { ProviderService } from "@/model/api/Provider";
import { POSPortfolioType } from "@/model/enums/POSEnum";
import { POSPortfolioAddProviderServiceDTO } from "@/model/ui/POSPortfolioAddProviderServiceDTO";
import { POSAddProviderServiceStepEnum } from "@/modules/pos/router/POSRoutesEnum";
import { posPortfolioService } from "@services/posPortfolio.service";
import { providerService } from "@services/provider.service";
import OverlayPanel from "primevue/overlaypanel";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
  },
  emits: ['goStep']
})
export default class ChooseProviderStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: POSPortfolioAddProviderServiceDTO;

  providerServices: ProviderService[] = [];

  created() {
    //this.dataModel.provider_service = null;

    this.$waitFor(async () => {
      this.providerServices = await posPortfolioService.getProviderServicesCompatibleByService(this.dataModel.service.id);
    });
  }

  goChooseService() {
    this.$emit('goStep', POSAddProviderServiceStepEnum.CHOOSE_SERVICE);
  }

  goProviderCalendar() {
    this.$emit('goStep', POSAddProviderServiceStepEnum.PROVIDER_CALENDAR);
  }

  overlayData: ProviderService = null;
  onMouseOver(event: MouseEvent, providerService: ProviderService) {
    
    this.overlayData = providerService;
    
    (this.$refs.evDetails as OverlayPanel).toggle(event);
  }
}
