import { POSPortfolio } from "@/model/api/POS";
import { POSAddServiceStepEnum, POSAddSingleServiceStepEnum, ServicePathsEnum } from "@/modules/pos/router/POSRoutesEnum";
import { posPortfolioService } from "@services/posPortfolio.service";
import { POSRoutesEnum } from "@/modules/pos/router";
import moment from "moment";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { POSPortfolioType } from "@/model/enums/POSEnum";
import OverlayPanel from "primevue/overlaypanel";

@Options({
  components: {
  },
  emits: ['goStep']
})
export default class CalendarStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: POSPortfolio;

  @Prop({default: ServicePathsEnum.STANDARD})
  readonly path!: string;

  events: any = [];
  minDate = new Date();
  startDate = new Date();
  currentDate = new Date();

  created() {
    this.dataModel.service_date_at = null;
    this.reloadOrders();
  }

  onViewChange(event: any) {
    this.currentDate = event.startDate;
    this.reloadOrders();
  }

  onCellClick(date: Date) {
    if (this.requestPending) {
      return;
    }

    if (!this.isValidDate(date)) {
      return;
    }
    this.dataModel.service_date_at = moment(date).format('YYYY-MM-DD');
    this.goNext();
  }

  goBack() {
    if (this.step != ServicePathsEnum.STANDARD)
      this.$emit('goStep', POSAddServiceStepEnum.ATTACHMENTS);
    else
      this.$emit('goStep', POSAddSingleServiceStepEnum.CHOOSE_SERVICE);
  }

  async goNext() {
    this.dataModel.type = POSPortfolioType.SINGLE_BY_POS;
    //TODO: Save service and get id
    this.dataModel.pos_service_id = this.dataModel.pos_service.id;
    this.dataModel.service = this.dataModel.pos_service.service;
    this.dataModel.service_id = this.dataModel.service.id;

    this.$emit('goStep', POSAddSingleServiceStepEnum.CONFIG);
  }

  getClassCell(cell: any, events: any[]) {
    if (!this.isValidDate(cell.formattedDate)) return 'blocked';
    if (events.length) return 'has-events';
    return 'available';
  }

  isValidDate(date: any) {
    if (moment(date).toISOString() < moment().toISOString()) {
      return false;
    }
    return true;
  }

  private reloadOrders() {
    this.$waitFor(async () => {
      const portfolio = await posPortfolioService.getCalendar(this.currentDate);

      portfolio
        .map(p => {
          p.time_slots = p.time_slots.map((ts: any) => {
            ts.service = p.service;
            return ts;
          })
          return p;
        })
        .flatMap(p => p.time_slots)
        .groupBy((ts: any) => `${ts.date_at}-${ts.service.id}`).forEach((tsList, key) => {
          if (!tsList.length) return;
          const timeslot = tsList[0] as any;

          this.events.push({
            start: timeslot.date_at,
            end: timeslot.date_at,
            title: timeslot.service.name || '-'
          });
        });
    });
  }

  overlayDate: string = null; 
  overlayData: any[] = null;
  onMouseOver(event: MouseEvent, cell: any, events: any[]) {
    this.overlayDate = cell.startDate.format('DD MMMM YYYY');

    if (!events?.length) {
      return;
    } 
    
    this.overlayData = events
      // .sort((
      //   {service_id: aid}, 
      //   {service_id: bid}
      // ) => aid - bid)
      .map(e => {
        return {
          service_name: e.title,
         }
      });
    
    (this.$refs.evDetails as OverlayPanel).toggle(event);

  }

  updated() {
    this.fixCalendar();
  }

  fixCalendar() {
    const vss = document.querySelectorAll<HTMLElement>('.vuecal__cell:not(.vuecal__cell--out-of-scope)');
    vss.forEach((vs) => {
      vs.setAttribute('style', 'display: "block"; min-height: 120px !important');
    });

    const values = ["23", "24", "25", "26", "27", "28", "29", "30", "31"];
    const ooss = document.querySelectorAll<HTMLElement>('.vuecal__cell--out-of-scope');

    ooss.forEach((oos) => {
        if (oos.hasChildNodes()) {
          const header = oos.firstChild.textContent.substring(0, 2);
          oos.removeChild(oos.firstChild);
          if (!values.includes(header)) {
            oos.setAttribute('style', 'display: "none"; min-height: 0 !important');
          }
        }
    });
  }
}
