import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "pos_add_provider_service_page",
  class: "page standard"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "content p-mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChooseServiceStep = _resolveComponent("ChooseServiceStep")!
  const _component_ChooseProviderStep = _resolveComponent("ChooseProviderStep")!
  const _component_ProviderCalendarStep = _resolveComponent("ProviderCalendarStep")!
  const _component_ChooseCouponStep = _resolveComponent("ChooseCouponStep")!
  const _component_ProviderSummaryStep = _resolveComponent("ProviderSummaryStep")!
  const _component_PaymentStep = _resolveComponent("PaymentStep")!
  const _component_PaymentResultStep = _resolveComponent("PaymentResultStep")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("section", _hoisted_2, [
      _createVNode("div", null, [
        _createVNode("h1", null, _toDisplayString(_ctx.$t('pos.portfolio.add_provider_service.title')), 1),
        _createVNode("span", {
          innerHTML: _ctx.$t("pos.portfolio.add_provider_service.subtitle")
        }, null, 8, ["innerHTML"])
      ])
    ]),
    _createVNode("section", _hoisted_3, [
      (_ctx.step == 'choose-service')
        ? (_openBlock(), _createBlock(_component_ChooseServiceStep, {
            key: 0,
            step: _ctx.step,
            path: _ctx.path,
            dataModel: _ctx.dataModel,
            onGoStep: _ctx.goStep
          }, null, 8, ["step", "path", "dataModel", "onGoStep"]))
        : (_ctx.step == 'choose-provider')
          ? (_openBlock(), _createBlock(_component_ChooseProviderStep, {
              key: 1,
              step: _ctx.step,
              path: _ctx.path,
              dataModel: _ctx.dataModel,
              onGoStep: _ctx.goStep
            }, null, 8, ["step", "path", "dataModel", "onGoStep"]))
          : (_ctx.step == 'provider-calendar')
            ? (_openBlock(), _createBlock(_component_ProviderCalendarStep, {
                key: 2,
                step: _ctx.step,
                path: _ctx.path,
                dataModel: _ctx.dataModel,
                onGoStep: _ctx.goStep
              }, null, 8, ["step", "path", "dataModel", "onGoStep"]))
            : (_ctx.step == 'choose-coupon')
              ? (_openBlock(), _createBlock(_component_ChooseCouponStep, {
                  key: 3,
                  step: _ctx.step,
                  path: _ctx.path,
                  dataModel: _ctx.dataModel,
                  onGoStep: _ctx.goStep
                }, null, 8, ["step", "path", "dataModel", "onGoStep"]))
              : (_ctx.step == 'provider-summary')
                ? (_openBlock(), _createBlock(_component_ProviderSummaryStep, {
                    key: 4,
                    step: _ctx.step,
                    path: _ctx.path,
                    dataModel: _ctx.dataModel,
                    onGoStep: _ctx.goStep
                  }, null, 8, ["step", "path", "dataModel", "onGoStep"]))
                : (_ctx.step == 'payment')
                  ? (_openBlock(), _createBlock(_component_PaymentStep, {
                      key: 5,
                      step: _ctx.step,
                      path: _ctx.path,
                      dataModel: _ctx.dataModel,
                      onGoStep: _ctx.goStep
                    }, null, 8, ["step", "path", "dataModel", "onGoStep"]))
                  : (_ctx.step == 'payment-result')
                    ? (_openBlock(), _createBlock(_component_PaymentResultStep, {
                        key: 6,
                        step: _ctx.step,
                        path: _ctx.path,
                        dataModel: _ctx.dataModel,
                        onGoStep: _ctx.goStep
                      }, null, 8, ["step", "path", "dataModel", "onGoStep"]))
                    : _createCommentVNode("", true)
    ])
  ]))
}