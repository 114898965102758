import { Service } from "@/model/api/Service";
import { POSPortfolioAddProviderServiceDTO } from "@/model/ui/POSPortfolioAddProviderServiceDTO";
import { POSAddProviderServiceStepEnum } from "@/modules/pos/router/POSRoutesEnum";
import { sumVat } from "@/utils/utils";
import { serviceBackofficeService } from "@services/services.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
  },
  emits: ['goStep'],
  
})
export default class ProviderSummaryStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: POSPortfolioAddProviderServiceDTO;

  goPayment() {
    this.$emit('goStep', POSAddProviderServiceStepEnum.PAYMENT);
  }

  goCoupon() {
    this.$emit('goStep', POSAddProviderServiceStepEnum.CHOOSE_COUPON);
  }

  get costVatIncluded() {
    const value = sumVat(this.dataModel.provider_service.cost, this.dataModel.provider_service.vat.value) - this.dataModel.coupon.value;
    return value >= 0 ? value : 0;
  }

  get costVatIncludedCouponExcluded(){
    return sumVat(this.dataModel.provider_service.cost, this.dataModel.provider_service.vat.value);
  }
}
