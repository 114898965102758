import { Options, Vue } from "vue-class-component";

import { POSRoutesEnum } from "../../router";
import { authStore } from "@/modules/auth/store";
import { Prop } from "vue-property-decorator";

import { POSPortfolio, POSService } from "@/model/api/POS";
import { POSAddRecursiveServiceStepEnum } from "../../router/POSRoutesEnum";

import ChooseServiceStep from "../services/add_steps/ChooseServiceStep.vue";
import AttachmentsStep from "../services/add_steps/AttachmentsStep.vue";
import CalendarStep from "./add_recursive_service_steps/CalendarStep.vue";
import TimeSlotsStep from "./add_recursive_service_steps/TimeSlotsStep.vue";
import POSPortfolioForm from "./POSPortfolioForm.vue";

@Options({
  components: {
    ChooseServiceStep,
    AttachmentsStep,
    CalendarStep,
    TimeSlotsStep,
    POSPortfolioForm
  }
})
export default class POSAddRecursiveServicePage extends Vue {
  @Prop()
  readonly step!: string;

  dataModel_service: POSService = new POSService();
  dataModel: POSPortfolio = new POSPortfolio();
  path = 'recurring';

  get me() {
    return authStore.getters.me;
  }

  created() {
    // first step
    if (this.step != POSAddRecursiveServiceStepEnum.CHOOSE_SERVICE) {
      this.$router.replace({ name: POSRoutesEnum.ADD_RECURSIVE_SERVICE, params: { step: POSAddRecursiveServiceStepEnum.CHOOSE_SERVICE } })
      return;
    }
  }

  async goStep(step: POSAddRecursiveServiceStepEnum) {
    await this.$router.push({ name: POSRoutesEnum.ADD_RECURSIVE_SERVICE, params: { step: step } });
    this.dataModel.pos_service = this.dataModel_service;
  }
}