import { Coupon } from "@/model/api/Coupon";
import { PaymentData } from "@/model/api/Payment";
import { Service } from "@/model/api/Service";
import { POSPortfolioAddProviderServiceDTO } from "@/model/ui/POSPortfolioAddProviderServiceDTO";
import { POSAddProviderServiceStepEnum } from "@/modules/pos/router/POSRoutesEnum";
import { sumVat } from "@/utils/utils";
import { posPortfolioService } from "@services/posPortfolio.service";
import { serviceBackofficeService } from "@services/services.service";
import { round } from "@turf/helpers";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { couponService } from "@services/coupon.service";

@Options({
  components: {
  },
  emits: ['goStep']
})
export default class PaymentStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: POSPortfolioAddProviderServiceDTO;

  coupons: Coupon[] = [];

  created() {
    // this.dataModel.provider_service = null;

    // this.$waitFor(async () => {
    //   this.coupons = await couponService.searchCoupons(
    //     this.dataModel.provider_service.service_id,
    //     this.dataModel.provider_service.entity_id);
    // });
  }

  book() {
    this.$waitFor(async () => {
      this.dataModel.payment_data = await posPortfolioService.bookProviderService(this.dataModel.provider_service.id, this.dataModel.service_date_at);
      this.nexiPayment(this.dataModel.payment_data);
    });
  }

  pay() {
    this.$waitFor(async () => {
      this.dataModel.pos_portfolio = await posPortfolioService.buyProviderService(this.dataModel.provider_service.id, this.dataModel.service_date_at);
      this.goPaymentResult();
    });
  }

  nexiPayment(payment_data: PaymentData) {
    const fields = ['alias', 'importo', 'divisa', 'codTrans', 'url', 'url_back', 'mac'];
    const form = document.createElement("form");
    form.method = "POST";
    form.action = payment_data.nexi_url;   

    for (const field of fields) {
      const input = document.createElement("input"); 
      input.value = payment_data[field];
      input.name = field;
      input.type = 'hidden';
      form.appendChild(input);  
    }

    document.body.appendChild(form);
    form.submit();
  }

  goPaymentResult() {
    this.$emit('goStep', POSAddProviderServiceStepEnum.PAYMENT_RESULT);
  }

  goSummary() {
    this.$emit('goStep', POSAddProviderServiceStepEnum.PROVIDER_SUMMARY);
  }
}
