import { POSPortfolio } from "@/model/api/POS";
import { POSAddRecursiveServiceStepEnum, POSAddServiceStepEnum, POSAddSingleServiceStepEnum, ServicePathsEnum } from "@/modules/pos/router/POSRoutesEnum";
import moment from "moment";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
  },
  emits: ['goStep']
})
export default class CalendarStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: POSPortfolio;

  currentWeekdays = [];
  weekdays = [
    { name: 'weekdays_short.1', key: 'day-1', iso: 1, count: 0 },
    { name: 'weekdays_short.2', key: 'day-2', iso: 2, count: 0 },
    { name: 'weekdays_short.3', key: 'day-3', iso: 3, count: 0 },
    { name: 'weekdays_short.4', key: 'day-4', iso: 4, count: 0 },
    { name: 'weekdays_short.5', key: 'day-5', iso: 5, count: 0 },
    { name: 'weekdays_short.6', key: 'day-6', iso: 6, count: 0 },
    { name: 'weekdays_short.7', key: 'day-7', iso: 7, count: 0 },
  ];

  created() {
    this.dataModel.weekdays = [1, 2, 3, 4, 5, 6, 7];
  }

  mounted() {
    this.filterWeekdays(this.startAt, this.endAt);
  }

  goBack() {
    if (this.step != ServicePathsEnum.STANDARD)
      this.$emit('goStep', POSAddServiceStepEnum.ATTACHMENTS);
    else
      this.$emit('goStep', POSAddSingleServiceStepEnum.CHOOSE_SERVICE);
  }

  async goNext() {
    this.dataModel.weekdays = this.dataModel.weekdays.filter(wd => this.currentWeekdays.map(cwd => cwd.iso).includes(wd));
    this.dataModel.weekdays.sort((a, b) => a - b);
    this.$emit('goStep', POSAddRecursiveServiceStepEnum.TIME_SLOTS);
  }

  onDateSelected(date: Date) {
    //if (!this.dataModel.weekdays.includes(moment(date).isoWeekday())) {
    //  return;
    //}

    const dateStr = moment(date).format('YYYY-MM-DD');

    const idxExDate = this.dataModel.excluded_dates.findIndex(ed => ed.date_at == dateStr);
    if (idxExDate >= 0) {
      this.dataModel.excluded_dates.splice(idxExDate, 1);
      //if (!this.dataModel.weekdays.includes(moment(date).isoWeekday())) {
      //  this.dataModel.weekdays.push(moment(date).isoWeekday());
      //}
    } else {
      this.dataModel.excluded_dates.push({ date_at: dateStr });
      // const day = this.currentWeekdays.find(wk => wk.iso == moment(date).isoWeekday());
      // if (day.count == 1) {
      //   this.dataModel.weekdays = this.dataModel.weekdays.filter(wd => wd != day.iso);
      // }  
    }
    this.filterWeekdays(this.startAt, this.endAt);
  }

  onMonthChange(e: Event) {
    //alert(JSON.stringify(e));
  }

  get canGoNext() {
    return this.dataModel.service_from_date_at && this.dataModel.service_to_date_at && this.dataModel.weekdays.length;
  }

  get tomorrow(): Date {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today;
  }

  get twoMonths(): Date {
    let date = new Date();
    if (this.startAt) date = new Date(this.startAt);
    date.setDate(date.getDate() + 60);
    return date;
  }

  public addAll() {
    this.dataModel.excluded_dates = [];
  }

  public removeAll() {
    this.addAll();
    let date = moment(this.startAt);
    while (date.isSameOrBefore(this.endAt)) {
      this.dataModel.excluded_dates.push({ date_at: moment(date).format('YYYY-MM-DD') } as any);
      date = date.clone().add(1, 'day');
    }
  }

  // --- startAt ---
  get startAt(): Date {
    const m = moment(this.dataModel.service_from_date_at, true);
    if (m.isValid()) {
      return m.toDate();
    }
    return this.dataModel.service_from_date_at as any;
  }

  set startAt(value) {
    const m = moment(value, true);
    this.dataModel.service_from_date_at = m.isValid() ? m.format('YYYY-MM-DD') : value as any;

    if (m.isValid()) {
      this.dataModel.excluded_dates = this.dataModel.excluded_dates.filter(exd => moment(exd.date_at).isBetween(this.dataModel.service_from_date_at, this.dataModel.service_to_date_at));
    }
    this.filterWeekdays(this.startAt, this.endAt);
  }

  // --- endAt ---
  get endAt(): Date {
    if (this.dataModel.service_to_date_at) {
      return moment(this.dataModel.service_to_date_at).toDate();
    }
    return this.startAt;
  }

  set endAt(value) {
    const m = moment(value);
    this.dataModel.service_date_at = this.dataModel.service_to_date_at = m.isValid() ? m.format('YYYY-MM-DD') : null;

    if (m.isValid()) {
      this.dataModel.excluded_dates = this.dataModel.excluded_dates.filter(exd => moment(exd.date_at).isBetween(this.dataModel.service_from_date_at, this.dataModel.service_to_date_at));
    }
    this.filterWeekdays(this.startAt, this.endAt);
  }

  get selectedWeekdays() {
    return this.dataModel.weekdays.map(d => {
      return this.currentWeekdays.find(wk => wk.iso == d);
    });
  }

  set selectedWeekdays(value) {
    this.dataModel.weekdays = value.map(d => d?.iso);
    //this.dataModel.excluded_dates = this.dataModel.excluded_dates.filter(exd => this.dataModel.weekdays.includes(moment(exd.date_at).isoWeekday()));
  }

  get multicalendarRange(): Date[] {
    if (this.isDateStartEndValid) {
      return [this.startAt, this.endAt];
    }
    return null;
  }

  set multicalendarRange(value) {
    return;
  }

  get excludedDates(): Date[] {
    return this.dataModel.excluded_dates.sort((a, b) => a.date_at.localeCompare(b.date_at)).map(x => moment(x.date_at).toDate());
  }

  set excludedDates(value) {
    this.dataModel.excluded_dates = value.map(x => {
      return { date_at: moment(x).format('YYYY-MM-DD') };
    }) as any;
  }

  get excludedDateModel() {
    return null;
  }

  set excludedDateModel(value) {
    this.dataModel.excluded_dates.push({ date_at: moment(value).format('YYYY-MM-DD') } as any);
  }

  removeExclDate(date: Date) {
    this.dataModel.excluded_dates = this.dataModel.excluded_dates.filter(x => x.date_at != moment(date).format('YYYY-MM-DD'));
  }

  getCustomDayContainerClass(slotProps) {
    const dateM = moment(`${slotProps.date.year}-${slotProps.date.month + 1}-${slotProps.date.day}`, 'YYYY-M-D');

    if (!dateM.isBetween(this.dataModel.service_from_date_at, this.dataModel.service_to_date_at, undefined, '[]')) return 'disabled';

    const dateStr = dateM.format('YYYY-MM-DD');
    if (this.dataModel.excluded_dates.findIndex(ed => ed.date_at == dateStr) >= 0) return 'excluded';
    if (!this.dataModel.weekdays.includes(dateM.isoWeekday())) return 'excluded';
    return 'included';
  }

  get allDisabledDates(): Date[] {
    if (!this.isDateStartEndValid) {
      return;
    }

    let res: Date[] = [];
    res = res.concat(this.excludedDates);

    let lastDate = moment(this.startAt);
    while (lastDate.isSameOrBefore(this.endAt)) {
      if (!this.dataModel.weekdays.includes(lastDate.isoWeekday())) {
        res.push(lastDate.toDate());
      }
      lastDate = lastDate.clone().add(1, 'day');
    }

    return res;
  }

  get isDateStartEndValid() {
    return moment(this.startAt, true).isValid() && moment(this.endAt, true).isValid();
  }

  filterWeekdays(fromDate, toDate) {
    this.currentWeekdays = [];
    this.weekdays.map(wd => wd.count = 0);

    while (fromDate <= toDate) {
      const ld = moment(fromDate).isoWeekday() - 1;
      const index = this.currentWeekdays.indexOf(this.weekdays[ld])
      if (index == -1) {
        this.currentWeekdays.push(this.weekdays[ld]);
      }
      if (!this.dataModel.excluded_dates.map(exd => exd.date_at).includes(moment(fromDate).format('YYYY-MM-DD'))) {
        if (this.weekdays[ld]) this.weekdays[ld].count++;
      }
      fromDate = moment(fromDate).add(1,'days');
    }
    this.currentWeekdays.sort(function(a, b) {
      return a.iso - b.iso;
    });
  }
}
