import { Options, Vue } from "vue-class-component";

import {
  DynamicTable,
  MyAutocomplete
} from "@components";

import { FilterMatchMode } from "primevue/api";
import { POSRoutesEnum } from "../../router";
import { POSPortfolio } from "@/model/api/POS";
import { posPortfolioService } from "@services/posPortfolio.service";
import { authStore } from "@/modules/auth/store";
import { Prop } from "vue-property-decorator";
import moment from "moment";
import OverlayPanel from "primevue/overlaypanel";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

@Options({
  components: {
    DynamicTable,
    MyAutocomplete
  }
})

export default class POSPortfolioListPage extends Vue {
  @Prop()
  readonly from_creation!: string;

  readonly stateTableKey = 'POSPortfolioListPage';
  filters: any = this.getNewFiltersSchema();
  isTableLoading = false;
  totalRecordsLength = 0;
  reloadCount = 0;
  showDeleteDialog: boolean = false;
  current: boolean = true;
  showSelected: boolean = true;

  selectedPortfolio: POSPortfolio;

  get datatable(): any {
    return this.$refs.datatable;
  }


  beforeRouteLeave() {
    if (posPortfolioService?.cancelPendingRequests) {
      posPortfolioService.cancelPendingRequests();
    }
  }

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(POSRoutesEnum.PREFIX)) {
      sessionStorage.removeItem('POSPortfolioListPage');
    }
    next();
  }

  get service() {
    return posPortfolioService;
  }

  get me() {
    return authStore.getters.me;
  }

  groupByDate() {
    this.datatable.groupByDate = '';
  }
  
  get posId() {
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      return 0;
    }

    return authStore.getters.me?.entity_id;
  }

  get additional() {
    return [
      { id: -1, name: this.$t('generic.pos') }
    ];
  }

  customIndexRequest(p) {    
    this.reloadCount++;
    const result = posPortfolioService.indexByPOS(this.me.entity_id, p);
    this.showSelected = false;
    return result;
  }

  goAddProviderService() {
    this.$router.push({ name: POSRoutesEnum.ADD_PROVIDER_SERVICE, params: { posId: 0 } });
  }

  goAddSingleService() {
    this.$router.push({ name: POSRoutesEnum.ADD_SINGLE_SERVICE });
  }

  goAddRecursiveService() {
    this.$router.push({ name: POSRoutesEnum.ADD_RECURSIVE_SERVICE });
  }

  goToDetail(model: POSPortfolio) {
    this.$router.push({ name: POSRoutesEnum.PORTFOLIO_EDIT, params: { portfolioId: model.id } });
  }

  onDelete(model: POSPortfolio) {
    this.selectedPortfolio = model;
    this.showDeleteDialog = true;
  }

  onToggle() {
    this.current = !this.current;
  }

  deleteService() {
    this.$waitFor(
      async () => {
          await this.service.remove(this.selectedPortfolio);
          this.showDeleteDialog = false;
          this.applyFilters();
          this.$successMessage(this.$t('pos.portfolio.delete_service.success'));
      },
      this.$t('pos.portfolio.delete_service.error')
    )
  }
  
  get filterConfiguredOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('provider.portfolio.detail.config_status_complete_label'), value: true },
      { name: this.$t('provider.portfolio.detail.config_status_not_complete_label'), value: false },
    ]
  }

  get filterActiveOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('generic.published'), value: true },
      { name: this.$t('generic.not_published'), value: false },
    ]
  }

  get empty() {
    return this.$t('pos.portfolio.list.empty_message');
  }

  get cols() {
    return [
      {
        field: 'service_name',
        header: this.$t('generic.input_name_label')
      },
      /*
      {
        field: 'service_categories_text',
        header: this.$t('pos.portfolio.detail.service_categories_label'),
        filterField: 'service_category_id'
      },
      */
      {
        field: 'provider_owner_name',
        header: this.$t('pos.portfolio.detail.provider_owner_name_label'),
        filterField: 'provider_owner_id',
        style: 'min-width: 10px; max-width: 150px;'
      },
      /*
      {
        field: 'is_configured',
        header: this.$t('pos.portfolio.detail.config_status_label'),
        style: 'min-width: 10px; max-width: 120px;'
      },
      */
      {
        field: 'is_active',
        header: this.$t('pos.portfolio.detail.active_status_label'),
        style: 'min-width: 10px; max-width: 120px;'
      },
      {
        field: 'service_date_at',
        header: this.$t('pos.portfolio.detail.service_date_label'),
        style: 'min-width: 10px; max-width: 120px;',
        headerColspan: 4
      },
      {
        field: 'gross_price',
        header: this.$t('pos.portfolio.detail.selling_price_label'),
        style: 'text-align: right; min-width: 10px; max-width: 100px;',
      }
    ];
  }

  getNewFiltersSchema() {
    return {
      service_name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      service_category_id: {
        style: 'min-width: 10px; max-width: 150px',
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      provider_owner_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      /*
      is_configured: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      */
      is_active: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      service_date_at: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      gross_price: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }

  get serviceDateAt(): Date {
    const m = moment(this.filters.service_date_at.value, true);
    return m.isValid() ? m.toDate() : null;
  }

  set serviceDateAt(value) {
    const m = moment(value, true);
    this.filters.service_date_at.value = m.isValid() ? m.format('YYYY-MM-DD') : value;
  }

  onTableLoadingChange(value: boolean) {
    this.isTableLoading = value;    
  }

  onTableTotalRecordsChange(value: number) {
    this.totalRecordsLength = value;
  }

  onTogglePortfolio(value: boolean) {
  }

  async created() {
    const esito = this.$route.query.esito;

    if (esito) {
      this.$errorMessage(this.$t('nexi.result', {esito: esito}));
    }
    if (this.from_creation != "true") this.showSelected = false;
  }
  
  applyFilters() {
    this.datatable.loadData();
  }

  resetFilters() {
    for (const key in this.filters) {
        this.filters[key].value = null;
    }

    this.applyFilters();
  }

  get sortField() {
    return this.from_creation == "true" ? "id" : "service_date_at";
  }
  

  onMouseOver(event: MouseEvent) {
    
    //this.overlayData = "Visualizza solo i servizi presenti nella tua Vetrina o solo quelli ancora in Bozza";
    
    (this.$refs.evDetails as OverlayPanel).toggle(event);
  }
}
