import { POSPortfolio } from "@/model/api/POS";
import { POSAddRecursiveServiceStepEnum } from "@/modules/pos/router/POSRoutesEnum";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { TimeSlot, DateWeekSelector } from "@components";
import { posPortfolioService } from "@services/posPortfolio.service";
import moment from "moment";
import { createSequenceFromTo, generateDates, shortTime } from "@/utils/utils";
import { ISlotEditable } from "@components/Modules/TimeSlot";
import { POSRoutesEnum } from "@/modules/pos/router";
import { POSPortfolioType } from "@/model/enums/POSEnum";

@Options({
  components: {
    TimeSlot,
    DateWeekSelector
  },
  emits: ['goStep']
})

export default class TimeSlotsStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: POSPortfolio;

  slotsEditable: ISlotEditable[] = [];
  slotsFixed: ISlotEditable[] = [];
  selectedWeekday: number = null;
  slotsEditableByWeekday = [];
  isLoadingSlots = false;

  showExceptions: boolean = false;

  created() {
    this.addAllAll();
    this.selectedWeekday = this.dataModel.weekdays[0];
    this.slotsEditable = this.loadTimeSlots();
    this.addAll();
  }

  addAllAll() {
    for (let index = 0; index < this.dataModel.weekdays.length; index++) {
      this.selectedWeekday = this.dataModel.weekdays[index];
      this.addAll();
    }
  }

  onChangeWeekday(weekday: number) {
    //this.loadTimeSlotsBusy();
    this.slotsEditable = this.slotsEditableByWeekday[this.selectedWeekday] || [];
  }

  @Watch('slotsEditable', { deep: true })
  onSlotsEditableChange(value, old) {
    this.slotsEditableByWeekday[this.selectedWeekday] = value;
  }

  async goNext() {
    const genDates = generateDates(this.dataModel.service_from_date_at, this.dataModel.service_to_date_at, 1, 'day').filter(d => {
      if (!this.dataModel.weekdays.includes(d.isoWeekday())) return false;
      if (this.dataModel.excluded_dates.findIndex(exd => exd.date_at == d.format('YYYY-MM-DD')) >= 0) return false;
      return true;
    });

    this.dataModel.time_slots = [];
    for (const d of genDates) {
      const slots = this.slotsEditableByWeekday[d.isoWeekday()] || [];
      const mapped = slots.map((s: any) => {
        return {
          from_time_at: s.from_time_at,
          to_time_at: s.to_time_at,
          date_at: d.format('YYYY-MM-DD')
        };
      });

      this.dataModel.time_slots = this.dataModel.time_slots.concat(mapped);
    }

    this.dataModel.is_configured = true;
    this.dataModel.type = POSPortfolioType.RECURSIVE_BY_POS;
    this.dataModel.service_id = this.dataModel.pos_service.service_id;
    this.dataModel.pos_service_id = this.dataModel.pos_service.id;
    this.dataModel.service = this.dataModel.pos_service.service;
    this.$emit('goStep', POSAddRecursiveServiceStepEnum.CONFIG);
  }

  goBack() {
    this.$emit('goStep', POSAddRecursiveServiceStepEnum.CALENDAR);
  }

  loadTimeSlots() {
    return [];
  }

  public addAll() {
    const [from_hrs, from_mins] = process.env.VUE_APP_TIME_SLOTS_START.substring(0,5).split(':');
    const [to_hrs, to_mins] = process.env.VUE_APP_TIME_SLOTS_END.substring(0,5).split(':');
    const duration = this.dataModel.pos_service.duration;
    const range = createSequenceFromTo(parseInt(from_hrs) * 60 + parseInt(from_mins), parseInt(to_hrs) * 60 + parseInt(to_mins), duration)
    .map(x => {
      return [Math.floor(x / 60), (x % 60)]
        .map(t => t.toString().padStart(2, '0'))
        .join(':');
    });

    this.slotsEditable = range.filter((s, idx) => idx < range.length - 1).map((s, idx) => {
      return {
        from_time_at: `${s}:00`,
        to_time_at: `${range[idx + 1]}:00`,
        title: this.dataModel.pos_service.description,
        time_label: null
      }
    });
}

  public removeAll() {
    this.slotsEditable = this.slotsEditableByWeekday[this.selectedWeekday];
    this.slotsEditable = [];
  }
}
