import { ProviderTechnicianCalendarStatusEnum } from "@/model/enums/ProviderTechnicianCalendarStatusEnum";
import { POSPortfolioAddProviderServiceDTO } from "@/model/ui/POSPortfolioAddProviderServiceDTO";
import { POSAddProviderServiceStepEnum } from "@/modules/pos/router/POSRoutesEnum";
import { posPortfolioService } from "@services/posPortfolio.service";
import moment from "moment";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
  },
  emits: ['goStep']
})
export default class ProviderCalendarStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: POSPortfolioAddProviderServiceDTO;

  events: any = [];
  minDate = new Date();
  startDate = new Date();
  currentDate = new Date();

  created() {
    //this.dataModel.service_date_at = null;
    this.reloadOrders();
  }

  onViewChange(event: any) {
    this.currentDate = event.startDate;
    this.reloadOrders();
  }

  onCellClick(date: Date, classStr: string) {
    if (classStr == 'blocked') {
      return;
    }
    this.dataModel.service_date_at = moment(date).format('YYYY-MM-DD');
    this.goChooseCoupon();
  }

  goChooseProvider() {
    this.$emit('goStep', POSAddProviderServiceStepEnum.CHOOSE_PROVIDER);
  }

  goChooseCoupon() {
    this.$emit('goStep', POSAddProviderServiceStepEnum.CHOOSE_COUPON);
  }

  getClassCell(cell: any, events: any[]) {
    if (!this.isValidDate(cell.formattedDate)) return 'blocked';

    if (events.length) {
      if (events.findIndex(e => !e.is_free) < 0) return 'available';
      if (events.findIndex(e => e.is_free) >= 0) return 'has-events';
    }

    return 'blocked';
  }

  isValidDate(date: any) {
    if (moment(date).toISOString() < moment().toISOString()) {
      return false;
    }
    return true;
  }

  private reloadOrders() {
    this.$waitFor(async () => {
      const cal = await posPortfolioService.getProviderServiceCalendar(this.dataModel.provider_service.id, this.currentDate);
      this.events = cal.map(o => {
        return {
          start: o.date_at,
          end: o.date_at,
          is_free: o.status == ProviderTechnicianCalendarStatusEnum.FREE,
          title: o.order?.service?.name
        }
      })
    });
  }

  updated() {
    this.fixCalendar();
  }

  fixCalendar() {
    const vss = document.querySelectorAll<HTMLElement>('.vuecal__cell:not(.vuecal__cell--out-of-scope)');
    vss.forEach((vs) => {
      vs.setAttribute('style', 'display: "block"; min-height: 120px !important');
    });

    const values = ["23", "24", "25", "26", "27", "28", "29", "30", "31"];
    const ooss = document.querySelectorAll<HTMLElement>('.vuecal__cell--out-of-scope');

    ooss.forEach((oos) => {
        if (oos.hasChildNodes()) {
          const header = oos.firstChild.textContent.substring(0, 2);
          oos.removeChild(oos.firstChild);
          if (!values.includes(header)) {
            oos.setAttribute('style', 'display: "none"; min-height: 0 !important');
          }
        }
    });
  }

}
