import { Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { POSRoutesEnum } from '@/modules/pos/router';
import { FormPage } from "@components";
import { POSPortfolio } from "@/model/api/POS";
import { posPortfolioService } from "@services/posPortfolio.service";
import POSPortfolioForm from "./POSPortfolioForm.vue";
import { PaymentResultData } from "@/model/api/Payment";
import { AttachmentTypeEnum } from "@/model/enums/AttachmentTypeEnum";

@Options({
  components: {
    POSPortfolioForm
  }
})
export default class POSPortfolioEditPage extends FormPage {
  @Prop()
  readonly portfolioId: number;

  @Prop()
  readonly path!: string;

  model: POSPortfolio = null;

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  async created() {
    await this.$waitFor(async () => {
      const mac = this.$route.query.mac;

      if (mac) {
        const importo = this.$route.query.importo as string;
        const esito = this.$route.query.esito;
        
        if (esito != 'OK') {
          this.$router.push({ name: POSRoutesEnum.PORTFOLIO_LIST });
        }
        try {
          const paymentResultData = {
            mac: mac,
            codTrans: this.$route.query.codTrans,
            esito: this.$route.query.esito,
            divisa: this.$route.query.divisa,
            data: this.$route.query.data,
            orario: this.$route.query.orario,
            codAut: this.$route.query.codAut,
            importo: parseInt(importo)
          } as PaymentResultData;
          this.model = await posPortfolioService.confirmProviderService(this.portfolioId, paymentResultData);
        } catch(e) {
          this.$errorMessage(this.$t('nexi.error'));
          this.$router.push({ name: POSRoutesEnum.PORTFOLIO_LIST });
        }
      } else {
        try {
          this.model = await posPortfolioService.getById(this.portfolioId);
          if (this.model.pos_service?.attachments.length > 0) {
            this.model.exam_att = this.model.pos_service.attachments.filter(att => att.type == AttachmentTypeEnum.EXAM_PREP);
            this.model.cust_att = this.model.pos_service.attachments.filter(att => att.type == AttachmentTypeEnum.GENERIC);
            this.model.serv_att = this.model.pos_service.attachments.filter(att => att.type == AttachmentTypeEnum.BY_PROVIDER_FOR_POS);
          } else if (this.model.provider_service?.attachments.length > 0) {
            this.model.exam_att = this.model.provider_service.attachments.filter(att => att.type == AttachmentTypeEnum.EXAM_PREP);
            this.model.cust_att = this.model.provider_service.attachments.filter(att => att.type == AttachmentTypeEnum.GENERIC);
            this.model.serv_att = this.model.provider_service.attachments.filter(att => att.type == AttachmentTypeEnum.BY_PROVIDER_FOR_POS);
          }
        } catch (e) {
          this.$router.back();
        }
      }
    });
  }

  onModelFormChanged() {
    this.modelFormChanged();
  }
}