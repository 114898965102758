import { Options, Vue } from "vue-class-component";

import { POSRoutesEnum } from "../../router";
import { authStore } from "@/modules/auth/store";
import { Prop } from "vue-property-decorator";
import { POSAddSingleServiceStepEnum } from "../../router/POSRoutesEnum";
import ChooseServiceStep from "../services/add_steps/ChooseServiceStep.vue";
import AttachmentsStep from "../services/add_steps/AttachmentsStep.vue";
import CalendarStep from "./add_single_service_steps/CalendarStep.vue";
import { POSPortfolio, POSService } from "@/model/api/POS";

import POSPortfolioForm from "./POSPortfolioForm.vue";

@Options({
  components: {
    ChooseServiceStep,
    AttachmentsStep,
    CalendarStep,
    POSPortfolioForm
  }
})
export default class POSAddSingleServicePage extends Vue {
  @Prop()
  readonly step!: string;

  dataModel_service: POSService = new POSService();
  dataModel: POSPortfolio = new POSPortfolio();
  path = 'single';

  get me() {
    return authStore.getters.me;
  }

  created() {
    // first step
    if (this.step != POSAddSingleServiceStepEnum.CHOOSE_SERVICE) {
      this.$router.replace({ name: POSRoutesEnum.ADD_SINGLE_SERVICE, params: { step: POSAddSingleServiceStepEnum.CHOOSE_SERVICE } });
      return;
    }

  }

  async goStep(step: POSAddSingleServiceStepEnum) {
    this.dataModel.pos_service = this.dataModel_service;
    await this.$router.push({ name: POSRoutesEnum.ADD_SINGLE_SERVICE, params: { step: step } });
  }
}