import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import {
  Form as VForm,
  Field as VField
} from 'vee-validate';
import { TimeSlot as TimeSlotComponent, DateWeekSelector } from "@components";
import { POSPortfolio } from "@/model/api/POS";
import { posPortfolioService } from "@services/posPortfolio.service";
import { generateDates, generateTimeSlots, shortTime } from "@/utils/utils";
import moment from "moment";
import { ISlotEditable } from "@components/Modules/TimeSlot";
import { POSRoutesEnum } from "@/modules/pos/router";
import { POSPortfolioType } from "@/model/enums/POSEnum";
import { TimeSlot } from "@/model/api/Domain";
import { createSequenceFromTo } from "@/utils/utils";
import { sumVat } from "@/utils/utils";
import { authStore } from "@/modules/auth/store";
import { AttachmentTypeEnum } from "@/model/enums/AttachmentTypeEnum";
import { posServiceService } from "@services/posService.service";
import { POSAddRecursiveServiceStepEnum, POSAddServiceStepEnum, POSAddSingleServiceStepEnum, ServicePathsEnum, POSAddProviderServiceStepEnum } from "../../router/POSRoutesEnum";
import { integer } from "@vee-validate/rules";

@Options({
  components: {
    VForm,
    VField,
    TimeSlotComponent,
    DateWeekSelector
  },
  emits: ['modelFormChanged', 'goStep']
})
export default class POSPortfolioForm extends Vue {
[x: string]: any;
  @Prop()
  readonly step!: string;
  
  @Prop({ default: false })
  readonly isFirstTime: boolean;

  @Prop({ default: null })
  readonly model!: POSPortfolio;

  @Prop({default: ServicePathsEnum.STANDARD})
  readonly path!: string;

  slotsEditable: ISlotEditable[] = [];
  slotsFixed: ISlotEditable[] = [];
  slotsEditable2: ISlotEditable[] = [];
  slotsFixed2: ISlotEditable[] = [];
  slotsEditable3: ISlotEditable[] = [];
  slotsFixed3: ISlotEditable[] = [];
  isTimeSlotVisible = false;
  examsDeliverableCount = 0;

  selectedDate: Date = null;
  slotsEditableByDate = new Map();
  isLoadingSlots = false;

  recursiveDates: Date[] = [];
  appDates: Date[] = [];

  async created() {
    
    if (this.isRecursiveType) {
      this.recursiveDates = generateDates(this.model.service_from_date_at, this.model.service_to_date_at, 1, 'day').filter(d => {
        if (!this.model.weekdays.includes(d.isoWeekday())) return false;
        if (this.model.excluded_dates.findIndex(exd => exd.date_at == d.format('YYYY-MM-DD')) >= 0) return false;
        return true;
      }).map(rd => rd.toDate());

      this.selectedDate = this.firstDay();
      this.loadTimeSlots();
      this.slotsEditableByDate = this.model.time_slots.groupBy(x => x.date_at);
      this.refreshSlotsEditable();
      //this.isTimeSlotVisible = true;
      this.model.service_time_from_at = "08:00:00";
      this.model.service_time_to_at = "20:00:00";
    } else {
      this.slotsEditable = this.model.time_slots as any;
      this.loadTimeSlots();
      //this.isTimeSlotVisible = !this.isFirstTime && this.model.service_time_from_at != null && this.model.service_time_to_at != null;
    }

    if (this.isFirstTime) {
      this.model.is_active = true;
    }

    this.$emit('modelFormChanged');
  }

  goNext() {
    // default first time all slots active
    //if (this.isFirstTime && this.model.service_time_from_at && this.model.service_time_to_at) {
    //  this.addAll();
    //}
    if (this.model.gross_price == 0) {
       this.$confirmMessage(this.$t("msg_form_prise_zero"))
        .then(async response => {
          if (response) {
            this.isTimeSlotVisible = true;
          }
      });
    } else {
      this.isTimeSlotVisible = true;
    }  
  }
  
  goPrevious() {
    if (this.isTimeSlotVisible) this.isTimeSlotVisible = !this.isTimeSlotVisible;
    else {
      if (this.path == ServicePathsEnum.SINGLE_SERVICE) {
        this.$emit('goStep', POSAddSingleServiceStepEnum.CALENDAR);
      } else if (this.path == ServicePathsEnum.RECURRING_SERVICE) {
        this.$emit('goStep', POSAddRecursiveServiceStepEnum.TIME_SLOTS);
      } else if ( this.path == ServicePathsEnum.PROVIDER ){
        this.$emit('goStep', POSAddProviderServiceStepEnum.PROVIDER_SUMMARY);
      } else {
        this.$router.back();
      }
    }
  }

  async savePosService() {
    this.model.pos_service.entity_id = authStore.getters.me.entity_id;
    this.model.pos_service.service_id = this.model.pos_service.service.id;
    this.model.pos_service.is_active = true;

    await this.$waitFor(async () => {
      let posService = null;
      if (this.model.pos_service.id) {
        posService = await posServiceService.updatePatch(this.model.pos_service);
      } else {
        posService = await posServiceService.create(this.model.pos_service);
      }

      const newAttachs = this.model.pos_service.attachments.filter(a => a._file);
      for (const attach of newAttachs) {
        await posServiceService.uploadAttachment(posService.id, attach._file, attach.description, attach.type);
      }
      this.model.pos_service_id = posService.id;
      this.model.service_id = posService.service.id;
    });
  }

  async doSave() {
    /*if (this.model.gross_price == 0) {
      this.$errorMessage("", this.$t('pos.price.error'));
      return;
    }*/
    if (this.isRecursiveType && this.model.gross_price == 0) {
      this.$confirmMessage(this.$t("msg_form_prise_zero"))
       .then(async response => {
         if (response) {
           this.isTimeSlotVisible = true;
         }
     });
   } else {
     this.isTimeSlotVisible = true;
   } 

    if (!this.model.pos_service_id && this.model.pos_service) {
      await this.savePosService();
    }
    this.updateTimeSlots();
    this.model.is_configured = true;

    await this.$waitFor(
      async () => {
        if (!this.model.id) {
          await posPortfolioService.create(this.model);
        } else {
          await posPortfolioService.update(this.model);
        }

        this.$emit('modelFormChanged');

        this.$successMessage(this.$t('data_saved_success'));
        this.$router.push({ name: POSRoutesEnum.PORTFOLIO_LIST,  params: { from_creation: "true" } });
      },
      this.$t('generic_error')
    );
  }

  setTimeStart() {
    if (this.isFirstTime && this.model.service_time_from_at && this.model.service_time_to_at) {
      this.addAll();
    }

    this.updateTimeSlots();
  }

  setTimeEnd() {
    if (this.isFirstTime && this.model.service_time_from_at && this.model.service_time_to_at) {
      this.addAll();
    }
    this.updateTimeSlots();
  }

  updateTimeSlots() {
    if (this.isRecursiveType) {
      this.model.time_slots = [];

      for (const [dateKey, slots] of this.slotsEditableByDate) {
        const mapped = slots.map((s: TimeSlot) => {
          return {
            from_time_at: s.from_time_at,
            to_time_at: s.to_time_at,
            date_at: dateKey
          };
        });

        this.model.time_slots = this.model.time_slots.concat(mapped);
      }
    } else {
      this.model.time_slots = this.slotsEditable.filter(s => s.from_time_at >= this.model.service_time_from_at && s.to_time_at <= this.model.service_time_to_at).map(s => {
        return {
          from_time_at: s.from_time_at,
          to_time_at: s.to_time_at,
          date_at: moment(this.model.service_date_at).format('YYYY-MM-DD')
        };
      });
    }

    this.examsDeliverableCount = this.model.time_slots.length;
  }

  dayInThePast(index: number) {
    const date = new Date(this.selectedDate);
    date.setDate(date.getDate() + index);
      if (moment(date).toDate()){ 
        return moment(date).toDate() <= new Date();
      }
  }

  headerSelectedDate(position: number){
    for (let index = 0; index < this.recursiveDates.length; index += 3) {
      if (this.recursiveDates[index] == this.selectedDate) {
        return this.recursiveDates[index + position];
        break;
      }
    }
  }

  nextDay(){
    for (let index = 0; index < this.recursiveDates.length; index += 3) {
      if (index + 3 >= this.recursiveDates.length) {
        this.selectedDate = this.recursiveDates[index];
        break;
      }
      if (this.recursiveDates[index + 3] > this.selectedDate) {
        this.selectedDate = this.recursiveDates[index + 3];
        break;
      }
    }
    this.loadTimeSlots();
    this.refreshSlotsEditable();

  }

  prevDay(){
    for (let index = 0; index < this.recursiveDates.length; index += 3) {
      if (this.recursiveDates[index + 3] == this.selectedDate) {
        this.selectedDate = this.recursiveDates[index];
        break;
      }
    }
    this.loadTimeSlots();
    this.refreshSlotsEditable();
  }

  firstDay() {
    const date = new Date();

    if (date <= this.recursiveDates[0]) return this.recursiveDates[0];
    else {
      for (let index = 0; index < this.recursiveDates.length; index += 3) {
        if (index + 3 >= this.recursiveDates.length || this.recursiveDates[index + 3] > date) return this.recursiveDates[index];
      }
    }
  }

  checkInRange(pos: number){
   const lastDay = this.recursiveDates[this.recursiveDates.length-1];
   const date = new Date(this.selectedDate);
    date.setDate(date.getDate() + pos);
    
    return lastDay >= date
  }

  get costVatIncluded() {
    if (this.model.provider_service) {
      const value = sumVat(this.model.provider_service?.cost, this.model.provider_service?.vat.value) - this.couponValue;
      return value >= 0 ? value : 0;
    } else return 0;
  }

  get costVatIncludedCouponExcluded() {
    if (this.model.provider_service)
      return sumVat(this.model.provider_service.cost, this.model.provider_service.vat.value);
    else return 0;
  }

  get couponValue() {
    if(this.model.order.used_coupon)
      return this.model.order.used_coupon.value;
    else return 0;
  }

  get income() {
    return this.model ? this.model.time_slots.length * this.model.gross_price : 0;
  }

  get inThePast() {
    if (this.isRecursiveType) {
      return moment(this.recursiveDates[this.recursiveDates.length-1]).toDate() <= new Date()
    } else {
      return moment(this.model?.service_date_at).toDate() <= new Date()
    }
  }

  get serviceCategoriesLabel() {
    return this.model?.service?.service_categories?.map(c => c.name).join(' - ') || '-';
  }

  get timeStartValues() {
    let slots = [];
    if (this.model.provider_service) {
      slots = generateTimeSlots(this.model.provider_service.time_from, this.model.provider_service.time_to, 30)
    } else {
      slots = generateTimeSlots(process.env.VUE_APP_TIME_SLOTS_START.substring(0,5), process.env.VUE_APP_TIME_SLOTS_START_MAX.substring(0,5), 30)
    }

    return slots.map(s => {
      return { name: s, code: `${s}:00` };
    });
  }

  get timeEndValues() {
    if (!this.model.service_time_from_at) return [];

    let slots = [];
    if (this.model.provider_service) {
      slots = generateTimeSlots(this.model.service_time_from_at, this.model.provider_service.time_to, 60, true)
    } else {
      slots = generateTimeSlots(this.model.service_time_from_at, process.env.VUE_APP_TIME_SLOTS_END.substring(0,5), 60, true)
    }

    return slots.map(s => {
      return { name: s, code: `${s}:00` };
    });
  }

  get selectedTimeStart() {
    return { name: shortTime(this.model.service_time_from_at), code: this.model.service_time_from_at };
  }

  set selectedTimeStart(value) {
    this.model.service_time_from_at = value.code;

    if (this.slotsEditable != null) this.slotsEditable = this.slotsEditable.filter(s => s.from_time_at >= value.code);
  }

  get selectedTimeEnd() {
    return { name: shortTime(this.model.service_time_to_at), code: this.model.service_time_to_at };
  }
  
  set selectedTimeEnd(value) {
    this.model.service_time_to_at = value.code;

    if (this.slotsEditable != null) this.slotsEditable = this.slotsEditable.filter(s => s.to_time_at <= value.code);
  }

  onTimeSlotsChange(timeSlots: string[]) {
    this.updateTimeSlots();
  }

  private async loadTimeSlots() {
    if (this.isProviderType) {
      const portfolio = await posPortfolioService.getCalendar(null, moment(this.model.service_date_at).toDate());
      for (const portf of portfolio) {
        if (portf.id == this.model.id) continue;

        const timeSlots = portf.time_slots.map(ts => {
          return {
            from_time_at: ts.from_time_at,
            to_time_at: ts.to_time_at,
            time_label: `${shortTime(ts.from_time_at)} - ${shortTime(ts.to_time_at)}`,
            title: portf.service.name
          };
        });
        this.slotsFixed = this.slotsFixed.concat(timeSlots);
      }
    } else if (this.isSingleType) {
      const portfolio = await posPortfolioService.getCalendar(null, moment(this.model.service_date_at).toDate());
      for (const portf of portfolio) {
        if (portf.id == this.model.id) continue;

        const timeSlots = portf.time_slots.map(ts => {
          return {
            from_time_at: ts.from_time_at,
            to_time_at: ts.to_time_at,
            time_label: `${shortTime(ts.from_time_at)} - ${shortTime(ts.to_time_at)}`,
            title: portf.service.name
          };
        });
        this.slotsFixed = this.slotsFixed.concat(timeSlots);
      }
    } else if (this.isRecursiveType) {
      this.slotsFixed = [];
      this.slotsFixed2 = [];
      this.slotsFixed3 = [];

      for (let index = 0; index < 3; index++) {
        this.isLoadingSlots = true;
        const date = new Date(this.selectedDate);
        date.setDate(this.selectedDate.getDate() + index);
        await this.$waitFor(async () => {
          const portfolio = await posPortfolioService.getCalendar(null, date);
          for (const portf of portfolio) {
            if (portf.id == this.model.id) continue;
            const timeSlots = portf.time_slots.map(ts => {
              return {
                from_time_at: ts.from_time_at,
                to_time_at: ts.to_time_at,
                time_label: `${shortTime(ts.from_time_at)} - ${shortTime(ts.to_time_at)}`,
                title: portf.service.name
              };
            });
            if (index == 0) this.slotsFixed = this.slotsFixed.concat(timeSlots);
            else if (index == 1) this.slotsFixed2 = this.slotsFixed2.concat(timeSlots);
            else this.slotsFixed3 = this.slotsFixed3.concat(timeSlots);
          }
          this.isLoadingSlots = false;
        });  
      }
    }
  }

  get excludedDatesLabel() {
    return this.model.excluded_dates.map(x => {
      return moment(x.date_at).format('DD/MM/YYYY');
    }).join(' | ');
  }

  get isRecursiveType() {
    return this.model.type == POSPortfolioType.RECURSIVE_BY_POS;
  }

  get isSingleType() {
    return this.model.type == POSPortfolioType.SINGLE_BY_POS;
  }

  get isProviderType() {
    return this.model.type == POSPortfolioType.BY_PROVIDER;
  }

  get weekdaysLabel() {
    return this.model.weekdays.map(x => this.$t(`weekdays_short.${x}`)).join('-');
  }

  selectedDateKey(index: number) {
    const date = new Date(this.selectedDate);
    date.setDate(date.getDate() + index);
    return this.selectedDate ? moment(date).format('YYYY-MM-DD') : null;
  }

  onChangeSelectedDate(date: Date) {
    this.loadTimeSlots();
    this.refreshSlotsEditable();
  }

  @Watch('slotsEditable', { deep: true })
  onSlotsEditableChange(value, old) {
    this.slotsEditableByDate.set(this.selectedDateKey(0), value);
    this.updateTimeSlots();
  }

  @Watch('slotsEditable2', { deep: true })
  onSlotsEditable2Change(value, old) {
    this.slotsEditableByDate.set(this.selectedDateKey(1), value);
    this.updateTimeSlots();
  }

  @Watch('slotsEditable3', { deep: true })
  onSlotsEditable3Change(value, old) {
    this.slotsEditableByDate.set(this.selectedDateKey(2), value);
    this.updateTimeSlots();
  }

  private refreshSlotsEditable() {
    this.slotsEditable = this.slotsEditableByDate.get(this.selectedDateKey(0)) || [];
    this.slotsEditable2 = this.slotsEditableByDate.get(this.selectedDateKey(1)) || [];
    this.slotsEditable3 = this.slotsEditableByDate.get(this.selectedDateKey(2)) || [];
  }

  public addAll() {
    const [from_hrs, from_mins] = (this.model.service_time_from_at || process.env.VUE_APP_TIME_SLOTS_START.substring(0,5)).split(':');
    const [to_hrs, to_mins] = (this.model.service_time_to_at || process.env.VUE_APP_TIME_SLOTS_END.substring(0,5)).split(':');
    const duration = this.model.provider_service ? this.model.provider_service.duration : this.model.pos_service.duration;
    const range = createSequenceFromTo(parseInt(from_hrs) * 60 + parseInt(from_mins), parseInt(to_hrs) * 60 + parseInt(to_mins), duration)
    .map(x => {
      return [Math.floor(x / 60), (x % 60)]
        .map(t => t.toString().padStart(2, '0'))
        .join(':');
    });

    this.slotsEditable = range.filter((s, idx) => idx < range.length - 1).map((s, idx) => {
      return {
        from_time_at: `${s}:00`,
        to_time_at: `${range[idx + 1]}:00`,
        title: this.model.service.name,
        time_label: null
      }
    });
  }

  public removeAll() {
    this.slotsEditable = [];
  }

}