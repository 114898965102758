import { POSPortfolioAddProviderServiceDTO } from "@/model/ui/POSPortfolioAddProviderServiceDTO";
import { POSAddProviderServiceStepEnum, POSRoutesEnum } from "@/modules/pos/router/POSRoutesEnum";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Service } from "@/model/api/Service";
import { sumVat } from "@/utils/utils";
import { serviceBackofficeService } from "@services/services.service";

@Options({
  components: {
  },
  emits: ['goStep']
})
export default class PaymentResultStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly path!: string;

  @Prop()
  readonly dataModel!: POSPortfolioAddProviderServiceDTO;

  goConfigureService() {
    this.$router.replace({ name: POSRoutesEnum.PORTFOLIO_EDIT, params: { path: this.path, portfolioId: this.dataModel.pos_portfolio.id, isFirstTime: true as any } });
  }
}
