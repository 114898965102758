import { Options, Vue } from "vue-class-component";

import { POSRoutesEnum } from "../../router";
import { authStore } from "@/modules/auth/store";
import { Prop } from "vue-property-decorator";
import { POSAddProviderServiceStepEnum } from "../../router/POSRoutesEnum";
import { POSPortfolioAddProviderServiceDTO } from "@/model/ui/POSPortfolioAddProviderServiceDTO";

import ChooseServiceStep from "./add_provider_service_steps/ChooseServiceStep.vue";
import ChooseProviderStep from "./add_provider_service_steps/ChooseProviderStep.vue";
import ProviderCalendarStep from "./add_provider_service_steps/ProviderCalendarStep.vue";
import ProviderSummaryStep from "./add_provider_service_steps/ProviderSummaryStep.vue";
import PaymentStep from "./add_provider_service_steps/PaymentStep.vue";
import PaymentResultStep from "./add_provider_service_steps/PaymentResultStep.vue";
import ChooseCouponStep from "./add_provider_service_steps/ChooseCouponStep.vue";
import POSPortfolioForm from "./POSPortfolioForm.vue";
import { Service } from "@/model/api/Service";
import { ProviderService } from "@/model/api/Provider";
import { Coupon } from "@/model/api/Coupon";

@Options({
  components: {
    ChooseServiceStep,
    ChooseProviderStep,
    ProviderCalendarStep,
    ProviderSummaryStep,
    ChooseCouponStep,
    POSPortfolioForm,
    PaymentStep,
    PaymentResultStep
  }
})
export default class POSAddProviderServicePage extends Vue {
  @Prop()
  readonly step!: string;
  path = 'provider';

  dataModel: POSPortfolioAddProviderServiceDTO = {
    service: new Service(),
    provider_service: new ProviderService(),
    service_date_at: null,
    pos_portfolio: null,
    coupon: new Coupon(),
    payment_data: null
  };

  get me() {
    return authStore.getters.me;
  }

  created() {
    if (this.step != POSAddProviderServiceStepEnum.CHOOSE_SERVICE) {
      this.$router.replace({ name: POSRoutesEnum.ADD_PROVIDER_SERVICE, params: { step: POSAddProviderServiceStepEnum.CHOOSE_SERVICE } });
      return;
    }
  }

  async goStep(step: POSAddProviderServiceStepEnum) {
    await this.$router.push({ name: POSRoutesEnum.ADD_PROVIDER_SERVICE, params: { step: step } });
  }
}
