import { POSService } from "@/model/api/POS";
import { Service } from "@/model/api/Service";
import { POSPortfolioType } from "@/model/enums/POSEnum";
import { POSPortfolioAddProviderServiceDTO } from "@/model/ui/POSPortfolioAddProviderServiceDTO";
import { POSAddProviderServiceStepEnum } from "@/modules/pos/router/POSRoutesEnum";
import { posPortfolioService } from "@services/posPortfolio.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
  },
  emits: ['goStep']
})
export default class ChooseServiceStep extends Vue {
  @Prop()
  readonly step!: string;

  @Prop()
  readonly dataModel!: POSPortfolioAddProviderServiceDTO;

  services: Service[] = [];  

  async created() {
    //this.dataModel.service = null;
    this.$waitFor(async () => {
      this.services = await posPortfolioService.getProviderServicesCompatible();
    });
  }

  goNext() {
    this.$emit('goStep', POSAddProviderServiceStepEnum.CHOOSE_PROVIDER);
  }
}
