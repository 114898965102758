import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "pos_add_recursive_service_page",
  class: "page standard"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "content p-mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChooseServiceStep = _resolveComponent("ChooseServiceStep")!
  const _component_AttachmentsStep = _resolveComponent("AttachmentsStep")!
  const _component_CalendarStep = _resolveComponent("CalendarStep")!
  const _component_TimeSlotsStep = _resolveComponent("TimeSlotsStep")!
  const _component_POSPortfolioForm = _resolveComponent("POSPortfolioForm")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _withDirectives(_createVNode("div", _hoisted_1, [
      _createVNode("section", _hoisted_2, [
        _createVNode("div", null, [
          _createVNode("h1", null, _toDisplayString(_ctx.$t('pos.portfolio.add_recursive_service.title')), 1),
          _createVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('pos.portfolio.add_recursive_service.subtitle')), 1)
        ])
      ]),
      _createVNode("section", _hoisted_4, [
        (_ctx.step == 'choose-service')
          ? (_openBlock(), _createBlock(_component_ChooseServiceStep, {
              key: 0,
              step: _ctx.step,
              path: _ctx.path,
              dataModel: _ctx.dataModel_service,
              onGoStep: _ctx.goStep
            }, null, 8, ["step", "path", "dataModel", "onGoStep"]))
          : (_ctx.step == 'attachments')
            ? (_openBlock(), _createBlock(_component_AttachmentsStep, {
                key: 1,
                step: _ctx.step,
                path: _ctx.path,
                dataModel: _ctx.dataModel_service,
                onGoStep: _ctx.goStep
              }, null, 8, ["step", "path", "dataModel", "onGoStep"]))
            : (_ctx.step == 'calendar')
              ? (_openBlock(), _createBlock(_component_CalendarStep, {
                  key: 2,
                  step: _ctx.step,
                  path: _ctx.path,
                  dataModel: _ctx.dataModel,
                  onGoStep: _ctx.goStep
                }, null, 8, ["step", "path", "dataModel", "onGoStep"]))
              : (_ctx.step == 'time-slots')
                ? (_openBlock(), _createBlock(_component_TimeSlotsStep, {
                    key: 3,
                    step: _ctx.step,
                    dataModel: _ctx.dataModel,
                    onGoStep: _ctx.goStep
                  }, null, 8, ["step", "dataModel", "onGoStep"]))
                : _createCommentVNode("", true)
      ])
    ], 512), [
      [_vShow, _ctx.step != 'config']
    ]),
    (_ctx.step == 'config')
      ? (_openBlock(), _createBlock(_component_POSPortfolioForm, {
          key: 0,
          path: _ctx.path,
          model: _ctx.dataModel,
          isFirstTime: true,
          onGoStep: _ctx.goStep
        }, null, 8, ["path", "model", "onGoStep"]))
      : _createCommentVNode("", true)
  ], 64))
}